

import {gmapApi} from 'vue2-google-maps'

const API_BKKBN = "https://statistik-server.konaweselatankab.go.id/apiStatistikBkkbn/";



export default {
  data() {
    return {
      // Tombol Modal
      edit: false,
      lihat: false,
      modal_lokasi: false,
      mdl_hapus: false,

      // Tombol Save Modal
      simpan1: false,
      simpan2: false,
      //
    tahun: [
				      { id: '', tahun: 'Pilih Tahun'},
				      { id: 2017, tahun: '2017'},
				      { id: 2018, tahun: '2018'},
				      { id: 2019, tahun: '2019'},
				      { id: 2020, tahun: '2020'},
				      { id: 2021, tahun: '2021'},
				      { id: 2022, tahun: '2022'},
				      { id: 2023, tahun: '2023'},
				      { id: 2024, tahun: '2024'},
				      { id: 2025, tahun: '2025'},
				      { id: 2026, tahun: '2026'},
				      { id: 2027, tahun: '2027'},
		    ],

      form : {
        id: '',
        kecamatan_id : '',
        des_kel_id : '',
        tahun: '',
        file: '',
      },
      lokasi: [],
      //
      file_old: "",
      file_path: this.$store.state.url.URL_APP + "uploads/",

      no: 0,
      id: '',
      url: {
        GET_URL: this.$store.state.url.URL_APP + "api/v1/petadasar/"
      },
      list_data: [],
      kecamatan: [],
      desa: [],

      page_first: 1,
      page_last: 0,
      cari_value: "",

    }
  },
  methods: {
  

    mapping: function(data) {

      var tampung = [];

      for(var i = 0; i < data[0].length; i++) {
          var lat = data[0][i][1];
          var lng = data[0][i];

          tampung.push({"lat":parseFloat(lat),"lng":parseFloat(lng)});
      }

      return tampung;


    },

    getKecamatan: function() {
        this.cek_load_data = true;
        fetch(API_BKKBN + "kecamatan", {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },

        })
          .then(res => res.json())
          .then(res_data => {
              this.kecamatan = res_data.data;

              this.kecamatan.unshift({ kecamatan_id: '', nama_kecamatan: 'Pilih Kecamatan'})
              this.cek_load_data = false;
          });
    },

    getDesa: function() {
        this.cek_load_data = true;
        fetch(API_BKKBN + "desa", {
          method: "POST",
          headers: {
            "content-type": "application/json"
          },
          body: JSON.stringify({
          kecamatan_id : this.form.kecamatan_id.id,
      })

        })
          .then(res => res.json())
          .then(res_data => {
              this.desa = res_data.data;
              this.desa.unshift({ des_kel_id: '', nama_des_kel: 'Pilih Desa'})
              this.cek_load_data = false;
          });
    },


    getView: function() {
      this.cek_load_data = true;
      fetch(this.url.GET_URL + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            data_ke: this.page_first,
            cari_value: this.cari_value
          })
        })
        .then(res => res.json())
        .then(res_data => {

          
          this.list_data = res_data[0].data1;
          this.page_last = res_data.lengthy;
          this.cek_load_data = false;
          
          // 


        });
    },


    editData: function() {

      var formData = new FormData();

      formData.append("id", this.form.id);
      formData.append("kecamatan_id", this.form.kecamatan_id.id);
      formData.append("nama_kecamatan", this.form.kecamatan_id.text);
      formData.append("des_kel_id", this.form.des_kel_id.id);
      formData.append("nama_des_kel", this.form.des_kel_id.text);
      formData.append("tahun", this.form.tahun);
      formData.append("file", this.form.file);
      formData.append("file_old", this.file_old);



      fetch(this.url.GET_URL + "editData", {
        method: "POST",
        headers: {
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        this.getView();
        this.edit = false;
      });
    },

    selectData: function(data, string) {

      if(string == "edit"){
        this.edit = true;

        this.form = {
          id: data._id,
          kecamatan_id: data.kecamatan_id,
          des_kel_id: data.des_kel_id,
          tahun: data.tahun,
          
          file: data.file,
        };
        this.file_old = data.file;
      }
      else if(string == "lokasi"){
        this.modal_lokasi = true;

        this.form = {
          
          kecamatan: data.kecamatan,
        }
        this.lokasi = data.geometry.coordinates;
        
        
      }
      else if(string == "hapus"){
        this.mdl_hapus = true;

        this.form = {
          id: data.id,
          subject_pajak_id: data.subject_pajak_id,
          nik: data.nik,
          nama: data.nama,
          alamat: data.alamat,
          kelurahan: data.kelurahan,
          kecamatan: data.kecamatan,
          rwrt: data.rwrt,
          no_telp: data.no_telp,
          file: data.file,
        };
        this.lokasi = data.lokasi;
        this.file_old = data.file;

        
      }
      else if (string == "lihat") {
        this.lihat = true;

        this.form.file = data.file;
        
      }


      // CKEDITOR.instances.editor1.setData(data.isi);
    },


    removeData: function(idnya, file) {
      fetch(this.url.GET_URL + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          id: idnya,
          file: file
        })
      }).then(res_data => {
        this.getView();
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        this.mdl_hapus = false;
      });
    },

    simulateProgress(number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },

    cari_data: function() {

      this.page_first = 1;
      this.getView();
    },

    Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },


    onFileSelected: function(event) {
      this.form.file = event.target.files[0];

    },

    btn_prev: function() {
      this.cek_load_data = true;
      if (this.page_first > 1) {
        this.page_first--
        this.no -= 10;
      } else {
        this.page_first = 1;
        
      }
      this.getView();
    },

    btn_next: function() {

      if (this.page_first >= this.page_last) {
        this.page_first == this.page_last
      } else {
        this.page_first++;
        this.no += 10;
      }
      this.getView();
    },

    addLokasi: function () {
     this.lokasi.push({ lat: 0 , lng: 0});
    },
    hapusLokasi: function () {
     this.lokasi.splice(-1,1);
    }

  },

  computed: {
    google: gmapApi
  },
  mounted() {

    this.getKecamatan();
    this.getView();

  }
}
